import React from 'react';
import Icon from '../../styles/atoms/icons';

const MainTestimonialContainer = ({ quote }) => (
  <div className="pull-quote">
    <Icon id="quoteIcon" isImage />
    <div
      className="pull-quote-wrapper"
      dangerouslySetInnerHTML={{
        __html: quote.body,
      }}
    />
    <div className="author-wrapper">
      <p className="author-name">{quote.person?.firstName} {quote.person?.lastName},&nbsp;</p>
      <p className="author-role">
        {quote.person?.role},&nbsp;
        {quote.person?.company.name}
      </p>
    </div>
  </div>
);

export default MainTestimonialContainer;
